import { render, staticRenderFns } from "./chart9.vue?vue&type=template&id=227f1df8&scoped=true"
import script from "./chart9.vue?vue&type=script&lang=js"
export * from "./chart9.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "227f1df8",
  null
  
)

export default component.exports